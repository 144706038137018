

@import '../asset/styles/mixin.scss';
@import '../asset/styles/variable.scss';





// .navbar{
//     @include navbar-section;
//     }
//     .nav-logo{
//     @include logo;
//     margin: 0; /* Remove any automatic centering */
//     display: inline-block;
//     }
//     .nav-items{
//     display: flex;
//     cursor :pointer;
    
//     }
//     
    
    
    .navbar_container{
      
    }
    .navbar{
  @include navbar-section;
    }
    .navbar-items{
      display: flex;
      justify-content: center;
      align-items: center;
    
     
    }
    
    .nav-logo{
      @include logo;
      color: $color-buttons;
    }

    .nav_list-item{
          @include list-sec;
          cursor :pointer;
          @media only screen and (max-width:$bp-medium){
            // display: none;
            display: block;
                   }
          @media only screen and (max-width:$bp-small){
          //  display: none;
          display: block;
         font-weight: 400;
                   }
          }