@import '../asset/styles/mixin.scss';
@import '../asset/styles/variable.scss';

.workshoppage-container {
    @include container;
    cursor: pointer;
    position: relative;
  
//    border: 2px solid red;
    overflow: hidden;

    /* Create a pseudo-element for the background */
    
}

.workshoppage-container::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   
    background-size: cover;
    background-position: center;
    filter: blur(9px);
    z-index: -1; 
}
.commonbg{
    position: relative;
  
   
    overflow: hidden;
}
.commonbg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6b9c8e5f;
// border-radius: 12px;
border: 1px solid rgba(255, 255, 255, 0.125);
background: linear-gradient(135deg, #1b4d1b, #d3c98f89);
    background-size: cover;
    background-position: center;
    filter: blur(6px);
    z-index: -1; /* Places it behind content */
}


.workshoppage{
@include first-section;
// border: 1px solid red
}
.workshoppagelive{
@include sec-section;
// border: 1px solid green;
margin-bottom: 4rem;
margin-top: 4rem;
display: grid;
grid-template-columns:60vw 30vw;
@media only screen and (max-width:$bp-medium){
    grid-template-columns: repeat(1,1fr);
              }
@media only screen and (max-width:$bp-small){
  grid-template-columns: repeat(1,1fr);
            }
}

.left-workshop{
    margin-top: 1rem;
   
   text-align: left;
    @media only screen and (max-width:$bp-small){
       margin-top: 0;
    //    margin-bottom: 1rem;
                  }
}

.left-para2{
    color: $color-buttons;
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: 600
}


.left-first-heading{
    @include first-heading;
    // color: $color-buttons;
font-weight: 600;
font-size: 3.4rem;
text-align: left;
color: $color-main-text;

@media only screen and (max-width:$bp-small){
    font-size: 2.5rem;
                }
}



.left-sec-heading{
    margin-top: 1rem;
    color:rgb(177, 172, 172);
    font-weight: 300;
font-size: 1.1rem;
    @media only screen and (max-width:$bp-small){
        font-size: 1.4rem;
                    }
}

.left-para-section{
    color: $color-main-text;
    margin-right: 5rem;
    font-size: 1.3rem;
    @media only screen and (max-width:$bp-small){
        font-size: 1.2rem;
        margin-right: 0rem;
                    }
}

.left-para-section2{
    color: $color-main-text;
    margin-right: 5rem;
    font-size: 1.2rem;
    @media only screen and (max-width:$bp-small){
        font-size: 1.2rem;
        margin-right: 0rem;
                    }
}
.android_right-workshop{
    @media only screen and (max-width:$bp-small){
        margin-bottom: 2rem;
                    }
}

.workshop-item2{
    margin-top: 3rem;
    @media only screen and (max-width:$bp-small){
       margin-bottom: 4rem;
                   }
}
.workshop-list{
    color: $color-main-text;
    margin-top: 1rem;
    display: flex;
    justify-content: left;
    align-items: center;
    list-style-type: none;
}
.workshop-icon{
    font-size: 2rem;
    margin-right:1rem
}


.about-workshop__section , .attend-section{
    @include first-section;
   
}

.about-workshop , .attend{
    @include sec-section;
   
}
.heading{
    @include first-heading;

   
}

.workshop-content{
    /* From https://css.glass */
// background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
margin-top: 4rem;

}
.html ,.css , .javascript ,.react-content ,.page ,.landing {

margin: 2rem  0rem;
/* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
// border: 1px solid rgba(255, 255, 255, 0.3);
padding: 1.2rem 2rem;
}
.content-list2{
    color: $color-main-text;
    margin: .7rem 0rem;
    list-style-type: none;
}
.workshop-content__heading2{
    color: $color-main-text;
    // margin: 1rem 0rem;
}


.webinar-section{
    @include first-section;
}
.webinar-content{
    @include sec-section;
}
.heading{
    @include first-section;
}

.webinar-about__section ,.attend-page{
    margin: 2rem  0rem;
/* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
// border: 1px solid rgba(255, 255, 255, 0.3);
padding: 1.2rem 2rem;
}

.aboutpara-text{
    color: $color-main-text;
    font-weight: 600;
    font-size: 1rem;
}
.webinar-about{
    color: $color-main-text;
   
    font-size: 1rem;
    margin: 1rem 0rem;
    
}
.emojies{
    font-size: 1.5rem;
}


.small-txt{
    color: white;
    font-size: 2rem;
    margin-top: 2rem;
    @media only screen and (max-width:$bp-small){
     font-size: 1.6rem;
                    }
}

// .reserve-spot{
//     max-width: 100vw;
//     // background: linear-gradient(115deg, #2c3e52, #fd746a);
//     background: linear-gradient(115deg, #9f6b1c, #f6562599);
// // border: 1px solid blue;
// margin-top: 6rem;
// // height: 50vh;
// // margin-bottom: 4rem;

// }
.reserve-section{
  margin: 3rem auto;
//   border: 1px solid green;
background-color: #6b9c8e5f;
// border-radius: 12px;
border: 1px solid rgba(255, 255, 255, 0.125);
  max-width: 100vw;
  padding: 1rem 0rem
}
.reserve{
    @include first-section;
    // border: 1px solid green;
margin: 4rem auto;
    
}


.reserve-heading{
    @include first-heading;
    color: $color-main-text;
//    border: 1px solid green;
   
}


.reserve-page{
    margin: 2rem  0rem;
/* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
padding: 1.2rem 2rem;

}




//  animation
.heading2{
    color: $color-buttons;
    margin-left: .8rem;
}


.attend-section2{
  
    margin:4rem 0rem
}
.android-section{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 4rem;
    // border: 1px solid green;
    @media only screen and (max-width:$bp-small){
        grid-template-columns: repeat(1,1fr);
        }

}
.attend-section{
    @include first-section;
  
}
.attend{
   
    @include sec-section;
  
    margin-bottom: 6rem;
}
.android-section3{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 4rem;
    @media only screen and (max-width:$bp-medium){
        grid-template-columns: repeat(1,1fr);
                                  }
    @media only screen and (max-width:$bp-small){
    grid-template-columns: repeat(1,1fr);
    }
   
  
}
.android-card{
    /* From https://css.glass */
    /* From https://css.glass */
    // background: rgba(124, 219, 117, 0.407);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.1px);
    -webkit-backdrop-filter: blur(2.1px);
    // border: 1px solid rgba(255, 255, 255, 0.13);
padding: 2rem 1rem;
// width: 200px;
// height: 200px;
@media only screen and (max-width:$bp-small){
    display: flex;
    // flex-direction: column;
        //   width: 120px;
        //   height: 160px;
          padding: 1rem
         
    }

}


.android-card2{
   
    background-color: #6b9c8e5f;
    // border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.1px);
    -webkit-backdrop-filter: blur(2.1px);
   
padding: 2rem 1rem;
// width: 200px;
// height: 200px;

@media only screen and (max-width:$bp-medium){
    display: flex;
    flex-direction: column;
        //   width: 120px;
        //   height: 120px;
          padding: 1rem
         
    }
@media only screen and (max-width:$bp-small){
    display: flex;
    flex-direction: column;
        //   width: 120px;
        //   height: 120px;
        //   padding: 1rem
         
    }



}

.first{
    // background-image: linear-gradient(to right, #bf9d56, #c88946, #d07240, #d65744, #d73751);
    background-color: #6b9c8e5f;
    // border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);

}

.second{
    background-color: #6b9c8e5f;
    // border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}
.third{
    background-color: #6b9c8e5f;
    // border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}
.fourth{
    background-color: #6b9c8e5f;
    // border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}

.fifth{
    background-color: #6b9c8e5f;
    // border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);

}
.sixth{
    background-color: #6b9c8e5f;
    // border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);

}
.card-para{
    color: $color-main-text;
    font-size: 1.1rem;
    display: flex;
    justify-content: left;
    align-items: center;
    // @media only screen and (max-width:$bp-small){
    // //    flex-direction: column;
    // //    justify-content: left;
    //                         }

}

.check-icon{
    width: 30px;
    height: 30px;
    color: white;
    margin-right: 1rem;
   
}

.android-part2{
    display: grid;
    grid-template-columns: 25vw 60vw;
 
   margin-top: 4rem;
    border-radius: 8px;
    padding: 1rem;


background: rgba(255, 255, 255, 0.08);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(2.1px);
-webkit-backdrop-filter: blur(2.1px);
border: 1px solid rgba(255, 255, 255, 0.13);


@media only screen and (max-width:$bp-large){
    // margin-left: 2rem;
    grid-template-columns: 30vw 50vw;
                              }
  
@media only screen and (max-width:$bp-medium){
    margin-left: 2rem;
    grid-template-columns: repeat(1,1fr)
                              }
  
@media only screen and (max-width:$bp-small){
    grid-template-columns: repeat(1,1fr);
   
    margin-left: 0;
   
                   }
}
.android-left2{
    display: flex;
    justify-content: center;
    align-items: center;
   
    margin-right: 2rem;
    @media only screen and (max-width:$bp-small){
      margin-right: 0;
                                  }
      
}

.android-right2{
    // border: 1px solid red;
    @media only screen and (max-width:$bp-medium){
        margin-right:1rem;
                                  }
      
}
.left-center2{
    // width: 300px;
    height: 300px;
    // background-color: white;
    // padding: 1rem;
    border-radius: 8px;
    // margin-right: 3rem;

    @media only screen and (max-width:$bp-small){
   margin-right: 0rem;
   height: 330px;
   width: 300px;
                       }
}

.android-img2{
width: 100%;
height: 100%;
border-radius: 8px;
}


.starimg{
    width: 20px;
    height: 20px;
    margin-right: .3rem;
}
.rating{
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.android-heading{
    color: $color-buttons;
    @media only screen and (max-width:$bp-small){
    margin-top: 2rem;
    text-align: center;
                            }
}
.android-list{
   margin: 1.3rem 0rem;
   padding-right: 1rem;
   @media only screen and (max-width:$bp-small){
       
    padding-right: 0;
                            }
}
.android-item{
    color: $color-main-text;
    list-style-type: none;
    margin: 1rem 0rem;
    @media only screen and (max-width:$bp-small){
       
        text-align: center;
                                }
}


.workshop-content__heading3{
    text-align: center;
    margin: 1rem;
    color: $color-main-text;
    @media only screen and (max-width:$bp-small){
       text-align: center;
                            }
}
.reserve-spot3{
    margin-top: 12rem;
}
.attend-section2{
    margin-top: 8rem;
    @include first-section
}

.nagarow{
    width: 120px;
    height: 80px;
}