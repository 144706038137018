
@import '../asset/styles/variable.scss';
@import '../asset/styles/mixin.scss';
.faq-container {
    margin: 20px auto;
    // max-width: 600px;
    font-family: Arial, sans-serif;
    // background-color: #1f1f1f;
    color: white;
    padding: 20px;
    border-radius: 8px;
    // border: 1px solid #333;
  }
  
  .faq-item {
    // border-bottom: 1px solid #444;
    padding: 20px;
    border-radius: 8px;
    
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
background-color:#6d9f6e3c;
  border: 1px solid  $card-title;
  
margin: 2rem 0rem;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
  
  }
  .answer{
    text-align: left;
    margin: 2rem 0rem;
  }
  
  .faq-icon {
    font-size: 20px;
    margin-left: 10px;
  }
  
  .faq-answer {
    overflow: hidden;
    max-height: 0; /* Start closed */
    transition: max-height 0.3s ease; /* Smooth transition */
    font-size: 16px;
    color: #ccc;
   
    width: 100%;
    text-align: left;
  }
  
  .faq-answer.open {
    max-height: 300px; /* Set to a large enough height for the answer */
  }
  