
@import '../asset/styles/variable.scss';
.footer-section{
    background: rgba(255, 255, 255, 0.2);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
   
   color: $color-main-text;
   

}

.footer{
   
   
   color: $color-main-text;

  
}

.footer-items{
    
    text-align: left;
    margin-left: 4rem;
    margin-top: 3rem;
    padding: 2rem 0rem;
    @media only screen and (max-width:$bp-small){
        display: flex;
        margin-left: 2rem;
        font-size: .9rem;
       padding: 1rem 0rem;
               }
}

.footer-heading{
    display: flex;
   
}
.footer-main__heading{
font-size: 1.5rem;
color: $color-buttons;
text-align: center;
cursor: pointer;
}


.footer-items__list{
    list-style-type: none;
    margin-right: 2rem;
    margin-top: .3rem;
    text-align: left;
    cursor: pointer;
    
}
.copyright{
    padding:1rem 0rem;
    text-align: center;
    cursor: pointer
}
