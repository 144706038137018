@import '../asset/styles/mixin.scss';
@import '../asset/styles/variable.scss';


// .workshopform-section{

// }
// .workshopform-form{

// }
.form-container{
    
border-radius: 8px ;
background-color: $color-main-text;
max-width: 30rem;
padding: .6rem 0rem;
}