
@import './asset/styles/variable.scss';


* ,
*::after,
*::before{
margin: 0;
padding: 0;
box-sizing: inherit;
}
  
// html{
//     box-sizing: border-box;
//  //10px/16px =62.5% ->1rem =10px
//     // @media only screen and (max-width:$bp-largest) {
//     //     font-size:50%;
//     // }
// }

body{
 
    background-color:#0C3B2E;
    // background-color: #254336;
    // background-color: #263A29;
    // background-color: #183A1D;
  
    font-weight: 300;
    line-height: 1.6;
    font-family: "Nunito", serif;

}