
@import './variable.scss';

@mixin container{
    max-width: 100vw;
    min-height: 50vh;
}
@mixin button{
    background-color:$color-bg;
    margin:2rem;
    padding: 1rem 3rem;
    color:$color-main-text;

}

@mixin logo{
    color: $color-buttons;
font-size: 2rem;
font-weight: 600;
@media only screen and (max-width:$bp-small){
   font-size: 1.3rem;
   font-weight: bold;
        }
}
@mixin list-sec{
    list-style-type: none;
margin-left: 2rem;
font-size: 1.2rem;
color: $color-main-text;
@media only screen and (max-width:$bp-small){
   font-size: .9rem;
        }
}
@mixin navbar-section{
   
    display: flex;
    justify-content: space-between;
    align-items: center;
padding: 1rem 4rem;

backdrop-filter: blur(16px) saturate(180%);
-webkit-backdrop-filter: blur(16px) saturate(180%);
background-color: #6b9c8e5f;
// border: 2px solid red;
// border: 1px solid rgba(255, 255, 255, 0.125);
@media only screen and (max-width:$bp-medium){
  padding: 1rem 2rem;
        }
        @media only screen and (max-width:$bp-small){
            padding: 1.5rem 1rem;
            
                  }

}


@mixin first-heading{
    color: $color-buttons;
font-size: 3rem;
text-align: center;
@media only screen and (max-width:$bp-small){

   font-size: 2rem;
   

       }
}

@mixin first-section{
    max-width: 90vw;
    // min-height: 20vh;
    // background-color: aquamarine;
// border: 2px solid red;
margin: 0 auto;
}
@mixin sec-section{
    margin-top: 8rem;
    // border: 2px solid rgb(38, 0, 128);
    @media only screen and (max-width:$bp-small){

      margin-top: 6rem;   
     
            }
}

@mixin grid-items{
    background-color: #6b9c8e5f;

box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
display: flex;
justify-content:left;
align-items: center;
color: $color-main-text;
font-weight: 600;
padding: .8rem .6rem;
border-radius: .2rem;
transition: all 0.6s ease;
margin-top: 1rem;
font-size: 1rem;
@media only screen and (max-width:$bp-small){

    font-size: .9rem;
    
 
        }
}

@mixin hover-transition{
    transform: scale(1.05)
}

@mixin grid-responsive{
    @media only screen and (max-width:$bp-medium){

        grid-template-columns: repeat(3,1fr);
       
    
           }
           @media only screen and (max-width:$bp-small){

            grid-template-columns: repeat(2,1fr);
           
        
               }
}


@mixin card_gradient{
    background-color: $card-gradient;

    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid $color-border;
        border-radius: 8px;
}