@import '../asset/styles/mixin.scss';
@import '../asset/styles/variable.scss';

.workshoppage-container {
    @include container;
    cursor: pointer;
    position: relative;
  
   
    overflow: hidden;

    /* Create a pseudo-element for the background */

    
    
}


.workshoppage-container::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    background-size: cover;
    background-position: center;
    filter: blur(12px);
    z-index: -1; 
}
.commonblue{
    position: relative;
  
  
    overflow: hidden;
    margin-bottom: 4rem;
}
.commonblue::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, #1b4d1b, #d3c98f89);
    background-size: cover;
    background-position: center;
    filter: blur(6px);
    z-index: -1; /* Places it behind content */
}


.workshoppage2{

margin: 4rem 2rem;

@media only screen and (max-width:$bp-small){
    grid-template-columns: repeat(1,1fr);
    margin: 0;
  
    
              }

}
.workshoppagelive2{
    // border: 1px solid red;
@include sec-section;
margin-top: 4rem;

display: grid;
grid-template-columns:60vw 30vw;
@media only screen and (max-width:$bp-medium){
    grid-template-columns: repeat(1,1fr);
              }
@media only screen and (max-width:$bp-small){
  grid-template-columns: repeat(1,1fr);
  margin-top: 3rem;
//   margin-top: 8rem;
            }
}

.left-workshop{
   
    margin:1rem 1rem ;
   text-align: left;
    @media only screen and (max-width:$bp-small){
       margin-top: 0;
    //    margin-bottom: 1rem;
                  }
}

.left-para{
    color: $color-buttons;
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: 600
}


.left-first-heading{
    @include first-heading;
    // color: $color-buttons;
font-weight: 600;
font-size: 3.4rem;
text-align: left;
color: $color-main-text;

@media only screen and (max-width:$bp-small){
    font-size: 2.5rem;
                }
}



.left-sec-heading{
    margin-top: 1rem;
    color:rgb(177, 172, 172);
    font-weight: 300;
font-size: 1.1rem;
    @media only screen and (max-width:$bp-small){
        font-size: 1.4rem;
                    }
}

.left-para-section{
    color: $color-main-text;
    margin-right: 5rem;
    font-size: 1.3rem;
    @media only screen and (max-width:$bp-small){
        font-size: 1.2rem;
        margin-right: 0rem;
                    }
}
.right-workshop{

    @media only screen and (max-width:$bp-small){
        margin-bottom: 2rem;
        display: none;
                    }
}

.workshop-item{
    margin-top: 3rem;
    @media only screen and (max-width:$bp-small){
       margin-bottom: 4rem;
                   }
}
.workshop-list{
    color: $color-main-text;
    margin-top: 1rem;
    display: flex;
    justify-content: left;
    align-items: center;
    list-style-type: none;
}
.javascript2 , .react-content2 , .css2 , .html2{
  
  
    margin: 2rem  0rem;
border: 1px solid $card-title;
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
// border: 1px solid rgba(255, 255, 255, 0.3);
padding: .2rem 2rem;

    // background: linear-gradient(115deg, #8f3203, #0e030099);
    // background-size: cover;
    // background-position: center;
}
.workshop-icon{
    font-size: 2rem;
    margin-right:1rem
}


.about-workshop__section , .attend-section{
    @include first-section;
   
}

.about-workshop , .attend{
    @include sec-section;
   
}
.heading{
    @include first-heading;

   
}

.workshop-content{
    /* From https://css.glass */
// background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
margin-top: 4rem;

}
.html ,.css , .javascript ,.react-content ,.page ,.landing {

margin: 2rem  0rem;
border: 1px solid $card-title;

background-color: transparent;
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
// border: 1px solid rgba(255, 255, 255, 0.3);
padding: .4rem 2rem;
}
.content-list{
    color: $color-main-text;
    margin: .7rem;
    font-size: 1.3rem;
}
.workshop-content__heading{
    color: $color-main-text;
    margin: 1rem 0rem;
    font-size: 1.3rem;
    display: flex;
    gap: 1rem;
    justify-content: left;
    align-items: center;
}

.workshop-content__heading2{
    color: $color-main-text;
    margin: 1rem 0rem;
    font-size: 1.3rem;
    
}
.content-list{
    list-style-type: none
}
.content-list2{
    font-size: 1.3rem;
    list-style-type: none
}
.webinar-section{
    @include first-section;
}
.webinar-content{
    @include sec-section;
}
.heading{
    @include first-section;
}

.webinar-about__section ,.attend-page{
    margin: 2rem  0rem;
/* From https://css.glass */
background-color: #6b9c8e5f;
// border-radius: 12px;
border: 1px solid rgba(255, 255, 255, 0.125);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
// border: 1px solid rgba(255, 255, 255, 0.3);
padding: 1.2rem 2rem;
}

.aboutpara-text{
    color: $color-main-text;
    font-weight: 600;
    font-size: 1rem;
}
.webinar-about{
    color: $color-main-text;
   
    font-size: 1rem;
    margin: 1rem 0rem;
    
}
.emojies{
    font-size: 1.5rem;
}


.small-txt{
    color: white;
    font-size: 2rem;
    margin-top: 2rem;
    @media only screen and (max-width:$bp-small){
     font-size: 1.6rem;
                    }
}

.reserve-spot2{
    margin-top: 8rem;
   
}

.reserve-section{
  margin: 3rem auto;
@include card_gradient;
  max-width: 100vw;
  border-radius: 0px;
  padding: 1rem 0rem
}
.reserve{
    @include first-section;

    // border: 1px solid green;
margin: 4rem auto;
    
}


.reserve-heading2{
    @include first-heading;
    color: $color-buttons;
//    border: 1px solid green;
   
}


.reserve-page{
    margin: 2rem  0rem;
/* From https://css.glass */
background-color: #6b9c8e5f;
// border-radius: 12px;
border: 1px solid rgba(255, 255, 255, 0.125);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
padding: 1.2rem 2rem;

}


.workshop-content__heading3{
    font-size: 1.3rem;
}

//  animation

.glass-effect {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    padding: 1rem;
  }
  

  
.check-icon{
    font-size: 4rem;
    // color: $card-title
}
.android-card2{
  
    @include card_gradient;
}


.vedeo{
   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
    @media only screen and (max-width:$bp-small){
       
       
                  }
   
}
.banner-card{
    display: none;
    @media only screen and (max-width:$bp-small){
       
       display: block;
    }
}
.banner-card-img{
    width: 100%;
    height: 100%;
}
.video-btn{
    background-color: $color-bg;
    font-size: 1rem;
    color: $color-main-text;
    border-style: none;width: 100%;
  text-align: center;
padding: 1rem 0rem;
border-radius: .3rem;
display: flex;
justify-content: center;
align-items: center;
margin: 1.5rem 0rem;
 

  @media only screen and (max-width:$bp-small){
     font-size: 1.1rem;
     display:flex;
             }
  
  
    
   
}
.workshop-video{
    display: none;
    @media only screen and (max-width:$bp-small){
        display: block;
        width: 100%;
     height: 80%;
                }
 
}
a{
 text-decoration: none;
 width: 100%;
}

.price-pay{
    width: 100%;
    font-size: 2rem;
    color: $color-main-text;
 
    display: none;
   
    @media only screen and (max-width:$bp-small){
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 1rem;
                }
 
}
.cost-pay{

width: 100%;
display: none;
@media only screen and (max-width:$bp-small){
    display: block;
    
            }




}
.pay2{
    font-size: 2rem;
    position: relative;
    color: gray;
}

.pay2::before{
   
        content:" ";
        height: 2px;
        width: 80px;
        background:#daa60a;
        display: block;
        position: absolute;
        top: 20%;
        left: -5px;
        z-index: 1;
    
        @media only screen and (max-width:$bp-largest){
            width: 110px;
           left: -1;
              }
              @media only screen and (max-width:$bp-medium){
            top: 40%;
                    }
    
}
.pay{
text-align:left;
font-size: 2rem;
    color: $color-main-text;
    margin: 0rem 0rem;
    
}

.language ,.clock-timer{
    display: flex;
    justify-content: left;
    align-items: left;
   margin-top: 1rem;
    gap: 1rem;
   
    width: 100%;
    display: none;
    @media only screen and (max-width:$bp-small){
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 1rem;
                }
 

}
.lang , .clock{
    color: white;
}
.hindi{
   


    // color: rgb(20, 234, 134);
    font-weight: 600;
    color: yellow;
    border: 1px dashed yellow;
    padding: 0rem 1rem;
}


.reviews-section{
    @include first-section;
}
.review-container{
    @include sec-section;
   
}
.review-heading{
    @include first-heading;
}
.review-img{
   
    margin: 5rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width:$bp-small){
      margin: 2rem 0rem;
                }
 
}
.page-image{
    width: 100%;
    height: 100%;
}
.image-section{
    
 max-width: 700px;
}

.workshop-item{
    display: flex;
    gap: 4rem;
    justify-content: left;
    align-items: center;
    @media only screen and (max-width:$bp-medium){
    
    flex-direction: column;
  
  
                   }
 @media only screen and (max-width:$bp-small){
   display: block;
                                   }

 
}

.workshop-shedule{
   
}
.workshop-timer{
    // border: 2px solid red;
    padding: 1rem;
    
    @media only screen and (max-width:$bp-small){
       display: none;
                  }

}
.time-icons{
    padding: 1rem;
}