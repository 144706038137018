@import '../asset/styles/variable.scss';
@import '../asset/styles/mixin.scss';

.loginform-section{
    @include first-section
}
.login-form{
    @include sec-section
}
.heading{
    @include first-heading
}

.login-container{
border: 1px solid green;

border-radius: 8px ;
background-color: $color-main-text;
// max-width: 20rem;
// background-color: aqua;
// margin: 2rem auto;
// padding: 0rem 1rem;
}

.login-fields{
// padding: 1rem;
// margin: 1rem 0rem;
text-align: left;

}
.form-heading{
    text-align: center;
    font-size: 1.5rem;
}
.form{
    width: 90%;
    // padding: .8rem 0rem;
    margin: .5rem 0rem;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
 padding: 1rem;
    outline: none;
}
.form:focus {
    border: none;
  }
.email{
    margin-top: 1.5rem;
}

.login-btn{
    width: 100%;
    border-style: none;
background-color: $color-buttons;
    color:$color-main-text;
    padding: 1rem 0rem;
  border-radius: 6px;
}