
@import '../asset/styles/variable.scss';
@import '../asset/styles/variable.scss';
/* Dropdown.css */

.dropdown-container {
  margin: 8rem auto;
  border-radius: 5px;
  background-color:#6d9f6e3c;
  border: 1px solid  $card-title;
  
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  
   backdrop-filter: blur(16px) saturate(180%);
   -webkit-backdrop-filter: blur(16px) saturate(180%);
   

  color: #fff;
  font-family: Arial, sans-serif;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  background-color: #6b9c8e07;
  border-bottom: 2px solid #52a67245;
  transition: background-color 0.3s ease;
}

.dropdown-header:hover {
  background-color: #6b9c8e5f;
}

.dropdown-header h2 {
  margin: 0;
  font-size: 18px;
}

.arrow {
  font-size: 16px;
  transition: transform 0.3s ease;
}

.arrow.rotate {
  transform: rotate(180deg);
}

.dropdown-content {
  overflow: hidden;
  max-height: 0; /* Start with max-height set to 0 */
  transition: max-height 0.3s ease-in-out; /* Smooth transition */
 
  background-color: #6b9c8e5f;
}

.dropdown-content.open {
  max-height: 800px; /* Set a sufficiently large value */
}

.content-item {
  padding: 15px;
  // border: 2px solid purple;
  border-radius: 5px;
  margin: 10px 15px;
  background-color: #292929;
  transition: background-color 0.3s ease;
  border: 1px solid  $card-title;
}

.content-item:hover {
  background-color: #333;
}

.content-item p {
  margin: 0;
  font-size: 14px;
  color: #aaa;
}

.content-item strong {
  color: #fff;
}

.skill_topic {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  gap: 10px;
}

.skill_topic h3 {
  margin: 0;
  font-size: 16px;
  color: #fff;
}

.img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.topics {
  margin: 1rem 1.5rem;
  padding-left: 15px;
  border-left: 2px solid #4D774E;
}

.topics p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #ccc;
}
