@import '../asset/styles/variable.scss';
@import '../asset/styles/mixin.scss';

 .card_left-front{
   
 }
 
 
 
 .card_left-front__card{
   

background-color: $color-main-text;
 border-radius: 8px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 width: 80%;
 padding: 1rem;
 backdrop-filter: blur(16px) saturate(180%);
 -webkit-backdrop-filter: blur(16px) saturate(180%);
 

 
 @media only screen and (max-width:$bp-largest){
    width: 90%;
 
 
        }
        @media only screen and (max-width:$bp-medium){
        width: 90%;
            }
            @media only screen and (max-width:$bp-small){
         width: 90%;
                  }
 
 }
 .card-first__section{
 margin-bottom: 1.5rem;
//  border: 1px solid red;
 

 }
 
 .image{
     width: 100%;
     height: 100%;
    object-fit: cover;
    object-position: center;
   
 }
 
 .card-second__section{
     // border: 2px solid blue;
     // margin: 0rem 1rem;
 }
 .card-second__heading{
  
   text-align: left;
   @media only screen and (max-width:$bp-large){
  font-size: 1rem;
     
        }
        @media only screen and (max-width:$bp-medium){
    font-size: .8rem;
             }
             
            @media only screen and (max-width:$bp-small){
             font-size: .9rem;
                    }
   
 }
 
 .card-price__section{
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin: 1rem 0rem;
 }
 .price{
     display: flex;
 //    border: 2px solid green;
 }
 .current{
     margin-right: .6rem;
     // border: 2px solid orange;
     font-size: 1.5rem;
     @media only screen and (max-width:$bp-largest){
        margin-right: 1rem;
        
           }
           @media only screen and (max-width:$bp-medium){
             margin-right: .5rem;
             font-size: 1rem;
                }
 }
 .prev-price{
     font-size: 1rem;
     position: relative;
     font-weight: 400;
     @media only screen and (max-width:$bp-medium){
       font-size: 1rem;
             }
    
 }
 
 .prev-price::before{
     content:" ";
     height: 2px;
     width: 60px;
     background:#608BC1;
     display: block;
     position: absolute;
     top: 30%;
     left: -5px;
     z-index: 1;
 
     @media only screen and (max-width:$bp-largest){
         width: 60px;
        left: 0;
           }
           @media only screen and (max-width:$bp-medium){
         top: 40%;
                 }
 }
 
 .card-btn{
     background-color: black;
     font-size: 1rem;
    color: $button-border;
     border-style: none;
     padding: .7rem 1rem;
 border-radius: .3rem;
 
 @media only screen and (max-width:$bp-largest){
     padding: .5rem 1rem;
     font-size: 1rem;
        }
        @media only screen and (max-width:$bp-large){
         font-size: .8rem;
         padding: .5rem .8rem;
            
               }
        @media only screen and (max-width:$bp-medium){
         padding: .5rem .5rem;
         font-size: .8rem;
            }
            
            @media only screen and (max-width:$bp-small){
             padding: .5rem .7rem;
                    }
 }
 
 
 .card-buy__course-btn{
     background-color: $color-bg;
     font-size: 1rem;
     color: $color-main-text;
     border-style: none;width: 100%;
   
 padding: 1rem 0rem;
 border-radius: .3rem;
 }
 
 
 .course-include{
     text-align: left;
     margin-top: 2rem;
 }
 .point{
     display: flex;
     justify-content: left;
     align-items: center;
 
     text-align: left;
     margin-top: 1rem;
 }
 .point-icon{
     margin-right: .5rem;
     font-size: 1.5rem;
     color: $color-buttons;
   
 }