@import '../asset/styles/mixin.scss';
@import '../asset/styles/variable.scss';

.workshop-container{
    @include first-section;
    // border: 1px solid rgb(96, 91, 155);
}

.workshop{
    @include sec-section;
    // border: 1px solid rgb(234, 164, 13);
   
}
.heading{
    @include first-heading;
}

.live-workshop{
    // border: 1px solid green;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
    margin-top: 5rem ;
    @media only screen and (max-width:"1100px"){
        grid-template-columns: repeat(2,1fr);
     
               }

    @media only screen and (max-width:$bp-medium){
        grid-template-columns: repeat(1,1fr);
     
               }
                
                @media only screen and (max-width:$bp-small){
        grid-template-columns: repeat(1,1fr);
     
               }
                
}
.live-section{
    background-color: $color-main-text;
    border-radius: 8px;
    box-sizing: border-box; /* Add this line */
    padding-bottom: 1rem;
   
}
.workshop-img{
  width: 100%;
//    height: 150px;
border-top-left-radius: 8px;
border-top-right-radius: 8px;

}

.workshop-lower__section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    margin: 2rem 1rem;
    // border: 1px solid red;
}

.live-heading{
   
    
}
.live-btn{
    padding: 1rem 3rem;
    color:$color-main-text;
    border: 1px solid $button-border;
    background: black;
    border-radius: 5px;
   
    font-size: 1.2rem;
    @media only screen and (max-width:$bp-small){
       font-size: .9rem;
               }

}

.live-last__heading{
    font-weight: 500;
    text-align: left;
    margin: 1rem 1rem;
   
}
.live-lastfirst__heading{
    font-weight: 600;
    text-align: left;
    margin: 1rem 1rem;
    font-size: 1.2rem;
  
}