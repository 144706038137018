.course-highlights {
    background-color: #0f0f0f; // Background matching your theme
    color: #fff;
    padding: 2rem;
  
    .course-title {
      text-align: center;
      font-size: 1.8rem;
      margin-bottom: 2rem;
      color: #00ff00; // Neon green title
    }
  
    .highlight-container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
  
      .highlight {
        display: flex;
        align-items: center;
        background: #202020;
        padding: 1.5rem;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 255, 0, 0.2);
  
        .icon-container {
          min-width: 50px;
          height: 50px;
          background: #00ff00;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;
  
          .icon-method,
          .icon-pricing,
          .icon-plan {
            font-size: 1.5rem; // Add your icon here
          }
        }
  
        .highlight-content {
          h3 {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
            color: #00ff00;
          }
  
          p {
            font-size: 0.9rem;
            line-height: 1.5;
          }
        }
      }
    }
  
    .action-button {
      margin-top: 2rem;
      text-align: center;
  
      button {
        background-color: #00ff00;
        color: #000;
        padding: 0.8rem 2rem;
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #00cc00;
        }
      }
    }
  }
  