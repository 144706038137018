@import '../asset/styles/variable.scss';
@import '../asset/styles/mixin.scss';

.loginform-section{
    @include first-section;
  
}
.login-form{
    // @include sec-section
   
   margin-top: .3rem;
}
.heading{
    @include first-heading
}

.login-container{
border: 1px solid green;

border-radius: 8px ;
background-color: $color-main-text;
max-width: 22rem;

margin: 0rem auto;
}

.login-fields{

margin: 1rem 1rem;
text-align: left;

}
input{
    padding-left: 2rem;
}
.form-heading{
    text-align: center;
}
.form{
    width: 90%;
    padding: .8rem 0rem;
    margin: .5rem 0rem;
    border-style: none;
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//   background-color: aqua;
    outline: none;
  border-radius: 3px;
   
}


.form:focus {
    border: none;
  }
.name{
    margin-top: 1.5rem;
}
.signup-email{
    margin-top: 0;
}

.login-btn{
    width: 100%;
    border-style: none;
background-color: $color-buttons;
    color:$color-main-text;
    padding: 1rem 0rem;
  border-radius: 6px;
}

.error-message{
    font-size: .7rem;
    color: red;
    text-align: right;
}
.last-error-message{
    font-size: .7rem;
    color: red;
    text-align: center;
    margin-top: .4rem;
}

.account{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
.account-txt{
    font-size: .8rem;
    font-weight: 600;

}
.account-txt-login{
    font-size: .8rem;
    font-weight: 600;
    margin-left: 1.5rem;
}