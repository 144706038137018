@import '../asset/styles/mixin.scss';
@import '../asset/styles/variable.scss';

.slider-container {
    width: 100%;
    max-width: 900px;
    overflow: hidden;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .slider-row {
    display: flex;
    width: 100%;
  }
  
  .employee-card {
    min-width: 300px;
    margin-right: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
  padding: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .slide-image__slider{
    width: 100%;
//    height: 100%;
  }
  
  .employee-card h2 {
    font-size: 1.5rem;
    color: #333;
  }
  
  .employee-card h4 {
    font-size: 1.1rem;
    color: #666;
  }
  
  .employee-card p {
    font-size: 0.9rem;
    color: #555;
  }
  
  .controls {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
  }
  
  .controls button {
    padding: 8px 16px;
    font-size: 1rem;
    background-color: $color-buttons;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .controls button:hover {
    background-color: $color-buttons;
  }
  