@import '../asset/styles/variable.scss';
@import '../asset/styles/mixin.scss';
.container{
 @include container;
   
}
.learn-section{
   @include first-section;
}
.learn{
    @include sec-section;
}

.learn-first__heading{
@include first-heading
}
.learn-second__heading{
    color: $color-main-text;
    font-size: 1.5rem;
    margin: 1rem 0rem;
    text-align: center;
    @media only screen and (max-width:$bp-small){

        font-size: 1.2rem;
        font-weight: 400;
        
     
            }
}

.learn-grid{

   @media only screen and (max-width:$bp-small){
    margin-top: 2rem;
        }

}

.perks-section{
  @include first-section;
  
}
.perk-benefits{
    @include sec-section;
}

.perks{
margin-bottom: 3rem;

}
.perks-heading{
    @include first-heading;
}

.perks-grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;
    align-items: center;
    gap: 2rem;
 
    @include grid-responsive;
    
  
}
.perks-div{
@include grid-items;
background-color:#6d9f6e3c;
border: 1px solid $color-border;
color: $color-main-text;
padding:2rem 1rem;
@media only screen and (max-width:$bp-small){
display: flex;
flex-direction: column;
      width: 120px;
      height: 100px;
      
     
}

}
.perks-div:hover{
    @include hover-transition
}
.perk-icon{

margin-right: 1rem;
color: $card-title;
@media only screen and (max-width:$bp-small){
margin-bottom: 1rem;
       
     
}
}
.perk-text{
  
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width:$bp-small){
       text-align: center;
               
             
        } 
}
.officon{
    font-size: 2rem;
  
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-section{
    @include first-section
}
.project{
    @include sec-section
}
.project-heading{
    margin-bottom: 4rem;

}
.heading{
    @include first-heading
}

.pro-heading{
    color: $color-main-text;
 margin-top: 1rem;
    text-align: left;
    font-weight: 400;
}
.project-grid{
    // max-width: 80vw;
    margin: 0 auto;
  
  
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;
    align-items: center;
    gap: 2rem;
 

    @media only screen and (max-width:$bp-large){

        grid-template-columns: repeat(2,1fr);
     
            }

  
    @media only screen and (max-width:$bp-medium){

        grid-template-columns: repeat(1,1fr);
     
            }

            
    @media only screen and (max-width:$bp-small){

        grid-template-columns: repeat(1,1fr);
        max-width: 90vw;
            }
}

.card-div{
padding: 1rem;
 
   background: rgba(255, 255, 255, 0.218);
   border-radius: .5rem;
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);
   transition: all .4s ease;
   border: 1px solid rgba(255, 255, 255, 0.3);
   @media only screen and (max-width:$bp-small){

        }

       
}

.card-div:hover{
    @include hover-transition;
}
.project-card{
    width: 100%;
    height: 90%;
    
}

.project-information{
max-width: 70rem;
text-align: left;
margin: 1rem auto;
background: rgba(255, 255, 255, 0.218);
border-radius: .5rem;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}
.project-list__item{
    list-style-type: none;
    color: $color-main-text;
    margin: 1rem;
}

.info-heading{
    color: $color-main-text;
    margin: 1rem;
    font-size: 1.5rem;
}
.heading{
color: $color-main-text;
font-size: 2rem;
margin-bottom: 1rem;


@media only screen and (max-width:$bp-small){

   font-size: 1.5rem;
   margin: 1rem auto;
 
        }
}
.image{
   width: 100%;
   height: 100%;
}

.project-left{
   
    text-align: left;
    padding: 0rem 3rem;
}
.project-list{
margin: 3rem 0rem;

}
.list{
    list-style-type: none;
    color: $color-main-text;
    margin-bottom: 1rem;
    
    @media only screen and (max-width:$bp-small){

        font-size: .9rem;
        
      
             }
    
}


.demo-vedio__section{
    @include first-section
}
.vedio-section{
    @include sec-section;
    margin-top: 8rem;
    // background-color:#6d9f6e3c;
    // border: 1px solid $color-border;
    border-radius: 8px;
    
}

.heading{
    @include first-heading
}
.demo-vedio{
    max-width: 60vw;
    margin: 2rem auto;
    height: 40vw;
    // border: 1px solid red;
    

    @media only screen and (max-width:$bp-medium){

        // border: 2px solid green;
    width: 500px;
    height: 400px;
        
               }
    @media only screen and (max-width:$bp-small){

        // border: 2px solid green;
       max-width: 100%;
        
               }
}
iframe{
    width: 100%;
    height: 70%;
}

.demo-heading{
    margin-top: 3rem;
}
.button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
.youtube-btn{
    background-color: black;
    padding: 1.2rem 3rem;
    
    color: $button-border;
    border-radius: 8px;
    border-style: none;
    font-size: 1.1rem;
    font-weight: 500;

    @media only screen and (max-width:$bp-small){

       font-size: 1.1rem;
          
        
               }
}


iframe{
    @media only screen and (max-width:$bp-small){

      width: 100%;
      height: 15rem;
        
      
             }
    
}
.question-section{
@include first-section
}
.question{
@include sec-section
}
.heading{
    @include first-heading;
    border: none;
    
}




