
   
 @import '../asset/styles/variable.scss';

  .contact-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 1rem;
  }
  
  .contact-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
  }
  
  .close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .close-close__icon{
    position: absolute;
    top: 2.5rem;
    left: 18rem;
    font-size: 2rem;
  }
  
  .center-model {
    margin-top: 1.5rem;
  }
  
  .center-model p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .contact-content {
      width: 90%;
      padding: 1.5rem;
    }
  
    .center-model p {
      font-size: 0.9rem;
    }
  
    .btn {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .contact-content {
      width: 85%;
      padding: 1rem;
      max-width: 320px; /* Limit max width for mobile */
  
      margin-right: 2rem;
    }
  
    .close-icon {
      top: 0.5rem;
      right: 0.5rem;
      font-size: 1.2rem;
    }
  
    .center-model p {
      font-size: 0.85rem;
    }
  
    .btn {
      padding: 0.5rem 1rem;
      font-size: 0.85rem;
    }
  }