$color-background:#222228;
$color-buttons:#FFBA00;
$color-main-text:white;
$color-text:#7AB2D3;
$color-sec-text:#88C273;
$color-bg:#131010;
$color-border:#aab964;
$button-border:#84C69B;
$card-title:#BED754;
$card-gradient:#95b1421c;

$bp-largest:75em; //1200px
$bp-large:62.5em; //1000px
$bp-medium :50em ; //800px
$bp-small:37.5em; //600px