// .slider-container {
//   position: relative;
//   width: 100%;
//   overflow: hidden;
// }

// .slider-row {
//   display: flex;
//   gap: 2rem;
// }

// .employee-cards {
//   min-width: 250px; // Adjust according to your slide width
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .icon-container {
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   background-color: rgba(0, 0, 0, 0.5);
//   color: #ffffff;
//   padding: 10px;
//   border-radius: 50%;
//   text-align: center;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   z-index: 10;
// }

// .icon-container.left {
//   left: 10px;
// }

// .icon-container.right {
//   right: 10px;
// }

// .icon {
//   font-size: 24px; // Adjust icon size as needed
// }


// .slider-container {
//   overflow: hidden;
//   position: relative;
//   width: 100%;
// }

// .slider-row {
//   display: flex;
//   transition: transform 0.5s ease-in-out;
// }

// .employee-cards {
//   flex-shrink: 0;
// }

// .icon-container {
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   cursor: pointer;
//   z-index: 10;
// }

// .left {
//   left: 10px;
// }

// .right {
//   right: 10px;
// }

.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider-row {
  display: flex;
  transition: transform 0.5s ease-in-out;
  gap: 2rem;
}

.employee-cards {
  flex-shrink: 0;
  width: 100%; /* Make each slide take up full width */
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.employee-cards img {
  width: 100%;
  height: auto;
  max-width: 300px; /* Optional: Limit the maximum size */
  object-fit: cover; /* Ensure the image scales properly */
}

.icon-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.icon-container.left {
  left: 10px;
}

.icon-container.right {
  right: 10px;
}

.icon {
  font-size: 24px; /* Adjust icon size */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .employee-cards img {
    max-width: 200px; /* Reduce size on smaller screens */
  }

  .icon-container {
    padding: 8px;
  }

  .icon {
    font-size: 20px; /* Adjust icon size for smaller screens */
  }
}

@media (max-width: 480px) {
  .employee-cards img {
    max-width: 150px;
  }

  .icon-container {
    padding: 6px;
  }

  .icon {
    font-size: 18px;
  }
}
.employee-cards {
  flex-shrink: 0;
  width: 100%; /* Ensure each card takes full width of the container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-cards img {
  width: 100%; /* Allow the image to scale with the card width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .employee-cards img {
    max-width: 90%; /* Remove fixed max-width for smaller screens */
  }
}

@media (max-width: 480px) {
  .employee-cards img {
    max-width: 100%; /* Allow full width usage on very small screens */
  }
}
