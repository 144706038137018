@import '../asset/styles/variable.scss';
@import '../asset/styles/mixin.scss';
.front-section{
    @include first-section;
 }
 .frontpart{
    
     display: grid;
     grid-template-columns:55vw 30vw;
 margin: 8rem 0rem;
 gap: 2rem;
 @media only screen and (max-width:$bp-medium){
     margin: 3rem 0rem;
 
        }
        @media only screen and (max-width:$bp-small){
       grid-template-columns: repeat(1,1fr);
       margin: 5rem 0rem;
             }
 }
 
 .right-front{
 //  border: 2px solid red;
     text-align: left;
     // margin: 4rem 0rem;
     @media only screen and (max-width:$bp-medium){
 
        
         margin: 0rem;
     
            }
 }
 
 .front-heading{
 color: $color-main-text;
 font-size: 1.8rem;
 @media only screen and (max-width:$bp-medium){
 
     font-size: 1.3rem;
        }
 
        @media only screen and (max-width:$bp-small){
        font-size: 1.2rem;
                }
 }
 .front-main__heading{
 font-size: 6rem;
 color: $color-main-text;
 @media only screen and (max-width:$bp-large){
 
     font-size: 4rem;
        }
 @media only screen and (max-width:$bp-medium){
 
     font-size: 3rem;
        }
        @media only screen and (max-width:$bp-small){
 
        
     font-size: 2.5rem;
     margin-top: 1rem;
   
     
            }
 
    
 }
 
 .front-mini-heading{
     margin-top: 1rem;
     color: #d1c0c0a5;
     font-size: 1.2rem;
     @media only screen and (max-width:$bp-small){
 
         
                }
 }
 .front-last__heading{
     color: $color-main-text;
     font-size: 1.7rem;
     
     @media only screen and (max-width:$bp-small){
         margin-top: 1rem;
         font-size: 1.2rem;
                }
 }
 
 
 
 
 
 
 .time-grid{
 display: grid;
 grid-template-columns: repeat(4 ,1fr);
 @include card_gradient;
 box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
 backdrop-filter: blur(5px);
 -webkit-backdrop-filter: blur(5px);
 max-width: 40vw;
 margin-top: 4rem;
 border-radius: 8px;
 margin-left: 1rem;
 padding: 1rem 0rem;
 justify-content: center;
 align-items: center;
 text-align: center;
 
 
 
            @media only screen and (max-width:$bp-medium){
             max-width: 100%;
             margin: 0;
             margin-top: 3rem;
             grid-template-columns: repeat(2 ,1fr);
             // border: 1px solid red;
             gap: 2rem;
                    }
 
                    
 @media only screen and (max-width:$bp-small){
     max-width: 100%;
     margin: 0;
     margin-top: 3rem;
     grid-template-columns: repeat(2 ,1fr);
     // border: 1px solid red;
     gap: 0rem;
            }
 
 }
 .from-time__grid{
   
     justify-content: center;
     align-items: center;
     margin: .2rem;
     border-right: 2px solid rgba(255, 255, 255, 0.074);
     // border: 1px solid green;
     padding: .5rem;
     @media only screen and (max-width:$bp-small){
     //    margin-top: 1rem;
     border-right: 2px solid rgba(255, 255, 255, 0.074);
     //    padding: .5rem 1rem;
                }
 }


 .start_batch{
    @media only screen and (max-width:$bp-small){
      margin-top: 0rem;
      margin-bottom: 1rem;
                   }
 }
 .from-time__grid-seclast{
   
    color: $card-title;
     justify-content: center;
    align-items: center;
    margin: .2rem;
   border-right: 2px solid rgba(255, 255, 255, 0.074);
    // border: 1px solid green;
    padding: .5rem;
 
     @media only screen and (max-width:$bp-small){
         border-right: 0;
                    }
 }
 .from-time__grid-last{
    color: $card-title;
      justify-content: center;
     align-items: center;
     margin: .2rem;
    
     // border: 1px solid green;
     padding: .5rem;
     @media only screen and (max-width:$bp-small){
     //    margin-top: 1rem;
     //    border: 1px solid red;
        padding: .5rem 1rem;
                }
 }
 
 .from-time__grid >p{
     // color: $color-main-text;
     font-size: 1rem;
     color: $card-title;
 
 @media only screen and (max-width:$bp-small){
     font-size: 1rem;
            }
   
 }
 .time-icon{
 font-size: 1.7rem;
 color: $color-buttons;
 transition: all .7s ease;
 @media only screen and (max-width:$bp-small){
     font-size: 2rem;
            }
 }
 .time-icon2{
     font-size: 1.7rem;
 color: $color-sec-text;
 transition: all .7s ease;
 @media only screen and (max-width:$bp-small){
     font-size: 2rem;
            }
 }
 
 .time-icon:hover{
    @include hover-transition
 }
 .time-icon2:hover{
     @include hover-transition
  }
  .time-icon3:hover{
     @include hover-transition
  }
  .time-icon4:hover{
     @include hover-transition
  }
 .time-icon3{
     font-size: 1.7rem;
 color: #CB9DF0;
 transition: all .7s ease;
 @media only screen and (max-width:$bp-small){
     font-size: 2rem;
            }
 }
 .time-icon4{
 font-size: 1.7rem;
 color: $color-text;
 transition: all .7s ease;
 @media only screen and (max-width:$bp-small){
     font-size: 2rem;
            }
 }
 
 
 .image{
     width: 100%;
     height: 100%;
    object-fit: cover;
    object-position: center;
   
 }
 
 .card-second__section{
     // border: 2px solid blue;
     // margin: 0rem 1rem;
 }
 .card-second__heading{
  
   text-align: left;
   @media only screen and (max-width:$bp-large){
  font-size: 1rem;
     
        }
        @media only screen and (max-width:$bp-medium){
    font-size: .8rem;
             }
             
            @media only screen and (max-width:$bp-small){
             font-size: .9rem;
                    }
   
 }
 
 .card-price__section{
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin: 1rem 0rem;
 }
 .price{
     display: flex;
 //    border: 2px solid green;
 }
 .current{
     margin-right: .6rem;
     // border: 2px solid orange;
     font-size: 1.5rem;
     @media only screen and (max-width:$bp-largest){
        margin-right: 1rem;
        
           }
           @media only screen and (max-width:$bp-medium){
             margin-right: .5rem;
             font-size: 1rem;
                }
 }
 .prev-price{
     font-size: 1rem;
     position: relative;
     font-weight: 400;
     @media only screen and (max-width:$bp-medium){
       font-size: 1rem;
             }
    
 }
 
 .prev-price::before{
     content:" ";
     height: 2px;
     width: 60px;
     background:#608BC1;
     display: block;
     position: absolute;
     top: 30%;
     left: -5px;
     z-index: 1;
 
     @media only screen and (max-width:$bp-largest){
         width: 60px;
        left: 0;
           }
           @media only screen and (max-width:$bp-medium){
         top: 40%;
                 }
 }
 
 .card-btn{
     background-color: black;
     font-size: 1rem;
     color: $color-main-text;
     border-style: none;
     padding: .7rem 1rem;
 border-radius: .3rem;
 
 @media only screen and (max-width:$bp-largest){
     padding: .5rem 1rem;
     font-size: 1rem;
        }
        @media only screen and (max-width:$bp-large){
         font-size: .8rem;
         padding: .5rem .8rem;
            
               }
        @media only screen and (max-width:$bp-medium){
         padding: .5rem .5rem;
         font-size: .8rem;
            }
            
            @media only screen and (max-width:$bp-small){
             padding: .5rem .7rem;
                    }
 }
 
 
 .card-buy__course-btn{
     background-color: $color-bg;
     font-size: 1rem;
     color: $color-main-text;
     border-style: none;width: 100%;
   
 padding: 1rem 0rem;
 border-radius: .3rem;
 }
 
 
 .course-include{
     text-align: left;
     margin-top: 2rem;
 }
 .point{
     display: flex;
     justify-content: left;
     align-items: center;
 
     text-align: left;
     margin-top: 1rem;
 }
 .point-icon{
     margin-right: .5rem;
     font-size: 1.5rem;
     color: $color-buttons;
   
 }