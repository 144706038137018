@import '../asset/styles/mixin.scss';
@import '../asset/styles/variable.scss';

.workshoppage-container {
    @include container;
    cursor: pointer;
    position: relative;
  
   
    overflow: hidden;

    /* Create a pseudo-element for the background */
    
}

.workshoppage-container::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: url("https://i.pinimg.com/736x/44/19/fc/4419fcd3ae55d67973bc00482e5e49b3.jpg");
    background-size: cover;
    background-position: center;
    filter: blur(9px);
    z-index: -1; 
}
.common{
    position: relative;
  
   
    overflow: hidden;
}
.common::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(115deg, #8f3203, #0e030099);
    background-size: cover;
    background-position: center;
    filter: blur(6px);
    z-index: -1; /* Places it behind content */
}


.workshoppage{
@include first-section;
// border: 1px solid red
}
.workshoppagelive{
@include sec-section;
margin-top: 4rem;

display: grid;
grid-template-columns:60vw 30vw;
@media only screen and (max-width:$bp-medium){
    grid-template-columns: repeat(1,1fr);
              }
@media only screen and (max-width:$bp-small){
  grid-template-columns: repeat(1,1fr);
            }
}

.left-workshop{
    margin-top: 1rem;
    // margin-bottom: 3rem;
   text-align: left;
    @media only screen and (max-width:$bp-small){
       margin-top: 0;
    //    margin-bottom: 1rem;
                  }
}

.left-para{
    color: $color-buttons;
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: 600
}


.left-first-heading{
    @include first-heading;
    // color: $color-buttons;
font-weight: 600;
font-size: 3.4rem;
text-align: left;
color: $color-main-text;

@media only screen and (max-width:$bp-small){
    font-size: 2.5rem;
                }
}



.left-sec-heading{
    margin-top: 1rem;
    color:rgb(177, 172, 172);
    font-weight: 300;
font-size: 1.1rem;
    @media only screen and (max-width:$bp-small){
        font-size: 1.4rem;
                    }
}

.left-para-section{
    color: $color-main-text;
    margin-right: 5rem;
    font-size: 1.3rem;
    @media only screen and (max-width:$bp-small){
        font-size: 1.2rem;
        margin-right: 0rem;
                    }
}
.right-workshop{
    @media only screen and (max-width:$bp-small){
        margin-bottom: 2rem;
                    }
}

.workshop-item{
    margin-top: 3rem;
    @media only screen and (max-width:$bp-small){
       margin-bottom: 4rem;
                   }
}
.workshop-list{
    color: $color-main-text;
    margin-top: 1rem;
    display: flex;
    justify-content: left;
    align-items: center;
    list-style-type: none;
}
.workshop-icon{
    font-size: 2rem;
    margin-right:1rem
}


.about-workshop__section , .attend-section{
    @include first-section;
   
}

.about-workshop , .attend{
    @include sec-section;
   
}
.heading{
    @include first-heading;

   
}

.workshop-content{
    /* From https://css.glass */
// background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
margin-top: 4rem;

}
.html ,.css , .javascript ,.react-content ,.page ,.landing {

margin: 2rem  0rem;
/* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
// border: 1px solid rgba(255, 255, 255, 0.3);
padding: 1.2rem 2rem;
}
.content-list{
    color: $color-main-text;
    margin: .7rem;
}
.workshop-content__heading{
    color: $color-main-text;
    margin: 1rem 0rem;
}


.webinar-section{
    @include first-section;
}
.webinar-content{
    @include sec-section;
}
.heading{
    @include first-section;
}

.webinar-about__section ,.attend-page{
    margin: 2rem  0rem;
/* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
// border: 1px solid rgba(255, 255, 255, 0.3);
padding: 1.2rem 2rem;
}

.aboutpara-text{
    color: $color-main-text;
    font-weight: 600;
    font-size: 1rem;
}
.webinar-about{
    color: $color-main-text;
   
    font-size: 1rem;
    margin: 1rem 0rem;
    
}
.emojies{
    font-size: 1.5rem;
}


.small-txt{
    color: white;
    font-size: 2rem;
    margin-top: 2rem;
    @media only screen and (max-width:$bp-small){
     font-size: 1.6rem;
                    }
}

// .reserve-spot{
//     max-width: 100vw;
//     // background: linear-gradient(115deg, #2c3e52, #fd746a);
//     background: linear-gradient(115deg, #9f6b1c, #f6562599);
// // border: 1px solid blue;
// margin-top: 6rem;
// // height: 50vh;
// // margin-bottom: 4rem;

// }
.reserve-section{
  margin: 3rem auto;
//   border: 1px solid green;
background-color: #6b9c8e5f;
// border-radius: 12px;
border: 1px solid rgba(255, 255, 255, 0.125);
  max-width: 100vw;
  padding: 1rem 0rem
}
.reserve{
    @include first-section;
    // border: 1px solid green;
margin: 4rem auto;
    
}


.reserve-heading{
    @include first-heading;
    color: $color-main-text;
//    border: 1px solid green;
   
}


.reserve-page{
    margin: 2rem  0rem;
/* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
padding: 1.2rem 2rem;

}




//  animation

