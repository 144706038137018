@import '../asset/styles/mixin.scss';
@import '../asset/styles/variable.scss';

.livecourse-container{
    @include first-section;
    // border: 1px solid rgb(96, 91, 155);
}

.livecourse{
    @include sec-section;
    // border: 1px solid rgb(234, 164, 13);
margin-top: 4rem
}
.heading{
    @include first-heading;
}

.livecourse_live-workshop{
  
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
    margin-top: 5rem ;
    
    @media only screen and (max-width:$bp-large){
        grid-template-columns: repeat(2,1fr);
     
               }
    @media only screen and (max-width:"1100px"){
        grid-template-columns: repeat(2,1fr);
     
               }

    @media only screen and (max-width:$bp-medium){
        grid-template-columns: repeat(1,1fr);
     
               }
                
                @media only screen and (max-width:$bp-small){
        grid-template-columns: repeat(1,1fr);
     
               }
                
}
.livecourse_live-section{

  background-color: $color-main-text;
  border-radius: 8px;
  box-sizing: border-box; /* Add this line */
  padding-bottom: 1rem;
}
.livecourse_workshop-img{
  width: 100%;
//    height: 150px;
border-top-left-radius: 8px;
border-top-right-radius: 8px;

}

.livecourse_workshop-lower__section{
    margin: 1rem 1rem;
  
}

.livecourse_live-heading{
  
}
.livecourse_live-btn{
    width: 100%;
    padding: 1rem 0rem;
    color:$color-main-text;
border: none;
    font-weight: 400;
    background: black;
    border-radius: 5px;
    // border-style: none;
    font-size: 1.5rem;
    
  
    @media only screen and (max-width:$bp-small){
       font-size: .9rem;
               }

}

.livecourse_live-lastmain__heading{
    font-weight: 600;
    text-align: left;
    margin: 1rem 1rem;
    // color: $color-main-text;
   
    font-size: 1.3rem;
}
.livecourse_live-last__heading{
    font-weight: 600;
    text-align: left;
    margin: 1rem 1rem;

    font-size: 1.1rem;
}
@keyframes gradient-border {
    0% {
      border-color: #0b580a;
    }
    50% {
      border-color: #ffdd00;
    }
    100% {
      border-color: #166459;
    }
  }
  
  .gradient-border-button {
    position: relative;
  
    font-size: 16px;
    font-weight: bold;
    border: 2px solid transparent;
    background-color: black;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.2s;
    animation: gradient-border 3s linear infinite;
  }
  
  .gradient-border-button:hover {
    transform: scale(1);
  }
  
